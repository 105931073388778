body {
  font: 14px "Century Gothic", Futura, sans-serif;
  margin: 0px;

  background-image: url("paleGreySmoke.jpg");
}

.fields {
  height: 14px;
}

ol, ul {
  padding-left: 30px;
}

.hoverLink:hover {
  cursor: pointer;
}

.kbd-navigation .square:focus {
  background: #ddd;
}

.ui.input input {
  background-color: lightyellow;
}

.modaledge {
  background-color: #f0fff0 !important;
}
