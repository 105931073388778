/* For sidebar formatting */

.ui.sidebar.my_sidebar {
    width: 415px !important;
    margin-left: -415px !important;
}
.ui.active.sidebar.my_sidebar {
    margin-left: 0px !important;
}
.ui.active.right.sidebar.my_sidebar {
    margin-left: -415px !important;
}


.ui.sidebar.my_phone_sidebar {
    width: 295px !important;
    margin-left: -295px !important;
}
.ui.active.sidebar.my_phone_sidebar {
    margin-left: 0px !important;
}
.ui.active.right.sidebar.my_phone_sidebar {
    margin-left: -295px !important;
}


.ui.sidebar.my_phone_landscape_sidebar {
    width: 315px !important;
    margin-left: -315px !important;
}
.ui.active.sidebar.my_phone_landscape_sidebar {
    margin-left: 0px !important;
}
.ui.active.right.sidebar.my_phone_landscape_sidebar {
    margin-left: -315px !important;
}


/* These aren't actually used */
.ui.custom.sidebar {
    width: 115px !important;
    margin-left: -115px !important;
}
.ui.active.custom.sidebar {
    margin-left: 0px !important;
}
.ui.active.right.custom.sidebar {
    margin-left: -115px !important;
}
