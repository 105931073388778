/* Use with the quoteSlider banner */

.qsContainer {
  position: relative;
  width: 100%;
}

.qsOverlay1 {
  position: absolute;
  bottom: 0;
  left: 100%;
  right: 0;
  background-color: #fffccc00;
  overflow: hidden;
  width: 100%;
  height: 100%;
  transition: .8s linear;
}
.qsOverlay2 {
  position: absolute;
  bottom: 0;
  left: 100%;
  right: 0;
  background-color: #fffccc00;
  overflow: hidden;
  width: 100%;
  height: 100%;
  transition: .8s linear;
}
.qsOverlay3 {
  position: absolute;
  bottom: 0;
  left: 100%;
  right: 0;
  background-color: #fffccc00;
  overflow: hidden;
  width: 100%;
  height: 100%;
  transition: .8s linear;
}
.qsOverlay4 {
  position: absolute;
  bottom: 0;
  left: 100%;
  right: 0;
  background-color: #fffccc00;
  overflow: hidden;
  width: 100%;
  height: 100%;
  transition: .8s linear;
}
.qsOverlay5 {
  position: absolute;
  bottom: 0;
  left: 100%;
  right: 0;
  background-color: #fffccc00;
  overflow: hidden;
  width: 100%;
  height: 100%;
  transition: .8s linear;
}
.qsOverlay6 {
  position: absolute;
  bottom: 0;
  left: 100%;
  right: 0;
  background-color: #fffccc00;
  overflow: hidden;
  width: 100%;
  height: 100%;
  transition: .8s linear;
}
.qsOverlay7 {
  position: absolute;
  bottom: 0;

  left: 0;
  right: 100%;

  width: 100%;
  height: 69%;

  color: #8313e2;
  text-align: center;

  font-family: Arial;
  font-size: 28px;
  overflow: hidden;
  transition: 0.9s linear;
}
.qsFadeIn7 {
  position: absolute;
  bottom: 0;

  width: 100%;
  height: 83%;

  color: #8313e2;
  text-align: center;

  font-family: Arial;
  font-size: 28px;
  overflow: hidden;
  animation: fadeIn 1.2s;
}


.slideIn1 .qsOverlay1 {
  width: 100%;
  left: 0;
}
.slideAway1 .qsOverlay1 {
  right: 100%;
  left: -100%;
}

.slideIn2 .qsOverlay2 {
  width: 100%;
  left: 0;
}
.slideAway2 .qsOverlay2 {
  right: 100%;
  left: -100%;
}

.slideIn3 .qsOverlay3 {
  width: 100%;
  left: 0;
}
.slideAway3 .qsOverlay3 {
  right: 100%;
  left: -100%;
}

.slideIn4 .qsOverlay4 {
  width: 100%;
  left: 0;
}
.slideAway4 .qsOverlay4 {
  right: 100%;
  left: -100%;
}

.slideIn5 .qsOverlay5 {
  width: 100%;
  left: 0;
}
.slideAway5 .qsOverlay5 {
  right: 100%;
  left: -100%;
}

.slideIn6 .qsOverlay6 {
  width: 100%;
  left: 0;
}
.slideAway6 .qsOverlay6 {
  right: 100%;
  left: -100%;
}

.fadeIn7 .qsFadeIn7 {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
.slideAway7 .qsOverlay7 {
  right: 100%;
  left: -100%;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
@-webkit-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
@-o-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
@-ms-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.qsTextVisible {
  color: #8313e2;
  font-size: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  text-align: center;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  /*white-space: nowrap;*/
}
.qsTextInvisible {
  /* This color is invisible - and matches the background */
  color: #fffccc00;
  font-size: 4px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

