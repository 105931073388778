@charset "UTF-8";


uniqueNormal { blah: #ddd;
}
/* leave this here ^ */

/* attempt to avoid horizontal scroll on mobile */
/* see: https://stackoverflow.com/questions/15086908/how-to-avoid-horizontal-scroll-on-mobile-web-with-responsive-web-design */
html { overflow-x: hidden; }

body {
  font: 14px "Century Gothic", Futura, sans-serif;
  margin: 0px;

  height: 100%;
}

/* these three used by masthead */
.fixed-header {
        top:   0;
        width: 100%;
        position: fixed;
        background-image: url("paleGreySmoke.jpg");
}
.fixed-header-blank {
        top:   0;
        width: 100%;
        position: fixed;
}
.stack-top{
        z-index: 9;
}
.cont {
        overflow-x: hidden;
        margin: 0 auto; /* Center the DIV horizontally */
}
/* ---------------------------- */

.settings-gear{
        position: fixed;
        top: 71px;
        left: 8px;
        z-index: 90;
}
.right-side-icons{
        position: fixed;
        top: 71px;
        right: 8px;
        z-index: 90;
}

/* FIXME: needed? *
.main{min-height:100%;
      padding-bottom:60px;
      box-sizing:border-box;}
/* -------------- */


/* see: https://semantic-ui-forest.com/templates/bootstrap/sticky-footer/ */
/* ACTUALL, see: https://matthewjamestaylor.com/bottom-footer             */
/* --- needed for fixed footer --- */

/* gap below root, see: https://stackoverflow.com/questions/52349245/react-css-root-div-full-browser-height  */
#root {
  height: 100vh;
}

@keyframes warningFlash {
  from {color: red;}
  to {color: yellow;}

}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-150%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes hideToTheLeft {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-150%);
  }
}

.fullScreen {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}


.warningCircle {
  top: 114px;

  animation: warningFlash;
  animation-delay: 0s;
  animation-timing-function: ease-out;
  animation-duration: 0.5s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}
#myOverlay {
  position: fixed;
  display: none;
  width: 50%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.70);

  animation: 0.4s ease-out 0s 1 slideInFromLeft;
}
#myNarrowOverlay {
  position: fixed;
  display: none;
  width: 50%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.70);

  animation: 0.4s ease-in 0s 1 hideToTheLeft forwards;
}

#myOverlayCanvas {
  position: absolute;
  width: 70%;
  top: 50%;
  left: 50%;
  font-size: 40px;
  color: white;
  transform: translate(-50%,-50%);
  -ms-transform: translate(-50%,-50%);
}



#container {
   min-height:95%;
   position:relative;
}
#header {
   background:#ff0;
}
#body {
   padding-bottom:50px;   /* Height of the footer */
}
#footer {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 50px;

        padding-top: 5px;
        margin-bottom: 0;

        /*padding-top: 10px;
        padding-bottom: 10px;
        background-color: #f5f5f540;
        margin-top: 0;
        margin-bottom: 0; */
}
/* -------------------------- */



.out-buildlinks-style {
  color: red;
}
.out-followlinks-style {
  color: blue;
}


ol, ul {
  padding-left: 30px;
}

.tx-search-bar {
  margin-bottom: 20px;
}

.tx-to-find {
  margin-right: 10px;
}
.tx-input-field {
  width: 64ch;
  margin-bottom: 10px;
}
.limb-input-field {
  width: 28ch;
  margin-bottom: 10px;
}

.build-locking-form {
  margin-bottom: 10px;
}

.hoverLink:hover {
  cursor: pointer;
}

.txid-display {
    font: 10px "Century Gothic", Futura, sans-serif;
}
.tx-stats-display {
  margin-bottom: 20px;
  /* border: 1px solid #999; */
  width: 64ch;
  height: 350px;
}

.specific-contract {
  background: wheat;
}
.specific-contract2 {
  background: paleturquoise;
}
.specific-contract3 {
  background: rgba(44, 158, 184, 0.536);
}
.specific-contract4 {
  background: rgba(144, 173, 247, 0.6);
}
.specific-contract5 {
  background: rgba(210, 111, 252, 0.6);
}
.specific-contract6 {
  background: rgba(111, 252, 134, 0.6);
}
.specific-contract7 {
  background: rgba(2, 138, 70, 0.6);
}
.specific-contract8 {
  background: rgba(248, 91, 143, 0.6);
}

.kbd-navigation .square:focus {
  background: #ddd;
}

.ui.input input {
  background-color: lightyellow;
}

.modaledge {
  background-color: #f0fff0 !important;
}


.sidebar {
    height: 100%;
    width: 0;
    position: fixed;
    top: 0;
    left: 0;
    padding-top: 40px;
    background: #c8c8ff;

    overflow-x: hidden;
    transition: 0.5s;
}

.sidebar div {
    padding: 8px 8px 8px 30px;
   /* font-size: 24px; */
    display: block;

    transition: 0.5s;
    cursor: pointer;
}
.mainBody {
    /* margin-left: 150px;
    font-size: 18px; */

    transition: margin-left .5s;
}


/*  NOT YET USED. Had trouble */
@bshzPurple: #8313e2;
@bshzLtPurple: #e7d0fb;
.justTopModalAndHeader {
    /* background-color: @bshzPurple; */
    background-color: #8313e2;
    border-radius: 20px;
}
/* .justTopModalContent */

/* These four are used for some homepage MOBILE modals                     */
.modalMobilePortrait {
  /* background-color: #f00ff0 !important; */
  height: 80%;
  bottom: 105px;

  position: relative;
}
.modalMobileLandscape {
  /* background-color: #f00ff0 !important; */
  height: 85%;
  bottom: 45px;

  position: relative;
}
.modalContentMobile {
  /* background-color: #f00ff0 !important; */
  height: 95%;
}
.modalBottomMobile {
  /* background-color: #f00ff0 !important; */
  height: 60px;
}
/* --------------------------------------------- */

.scrollFull {
  background-color: #fee9ff;
  height: 550px;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: left;
  padding: 0px;
}
.scrollGuest {
  background-color: #7ee9ff;
  height: 500px;
  overflow-x: hidden;
  text-align: left;
  padding: 0px;
}
.scroll {
  background-color: #fee9ff;
  width: 375px;
  height: 285px;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: left;
  padding: 10px;
}
.scroll0 {
  white-space: nowrap;
  background-color: #fee9ff;
  width: 375px;
  height: 35px;
  overflow-x: auto;
  overflow-y: hidden;
  text-align: left;
  padding: 6px;
}
